#overallContainer{
    height: 100vh;
    background: #5F0000;
}

#verticalHexagon {
    color: white;
    background: rgb(114, 0, 0);
    width: auto;
    height: auto;
    max-width: 90vw; /* Limits the width to 90% of the viewport width */
    max-height: 90vh; /* Limits the height to 90% of the viewport height */
    min-width: 300px; /* Ensures the hexagon doesn't get too narrow */
    min-height: 400px; /* Ensures a minimum height for comfortable viewing */
    clip-path: polygon(50% 2%, 96% 20%, 96% 80%, 50% 98%, 4% 80%, 4% 20%);
    position: relative;
    padding: 7rem 5rem; /* Adjusted padding to provide spacing around the content */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: transform 0.3s, box-shadow 0.3s;
    box-sizing: border-box; /* Ensures padding is included in width/height calculations */
    filter: drop-shadow(16px 16px);
}

.hexagon-box {
    color: var(--text-color, white);
    background: var(--background-color, rgb(114, 0, 0));
    width: auto;
    height: auto;
    max-width: var(--max-width, 90vw);
    max-height: var(--max-height, 90vh);
    min-width: 300px;
    min-height: 400px;
    clip-path: polygon(50% 2%, 96% 20%, 96% 80%, 50% 98%, 4% 80%, 4% 20%);
    position: relative;
    padding: var(--padding, 2rem 1.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    transition: transform 0.3s, box-shadow 0.3s;
    box-sizing: border-box;
    filter: drop-shadow(16px 16px);
}

@media (max-width: 768px) {
  .hexagon-box {
    padding: 1.5rem 1rem;
    min-height: 350px;
  }
}

@media (max-width: 480px) {
  .hexagon-box {
    padding: 1rem 0.75rem;
    min-height: 300px;
  }
}

.signin-form {
  display: flex;
  flex-direction: column;
}

.signin-form h2 {
  margin-bottom: 1rem;
}

.signin-form .form-group {
  margin-bottom: 0.75rem;
}

.signin-form .form-label {
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
}

.signin-form .form-control {
  font-size: 0.9rem;
  padding: 0.4rem 0.8rem;
}

.signin-form .btn {
  font-size: 0.9rem;
  padding: 0.4rem 0.8rem;
  margin-top: 0.5rem;
}

.signin-form .text-center {
  margin-top: 0.5rem;
}

.signin-form .text-center span {
  font-size: 0.8rem;
}

#verticalHexagonWrapper {
    position: relative;
    width: 100%;
    padding-top: 57.75%; /* The height should be 57.75% of the width for a perfect hexagon */
    background: rgb(114, 0, 0);
    overflow: hidden;
}

#verticalHexagonWrapper::before,
#verticalHexagonWrapper::after {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    background: inherit;
    left: 0;
}

#verticalHexagonWrapper::before {
    top: -50%;
    transform: rotate(60deg);
    transform-origin: center;
}

#verticalHexagonWrapper::after {
    top: -50%;
    transform: rotate(-60deg);
    transform-origin: center;
}



.customInput{
    width: 100%;
    padding: 0.75rem;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
}

.customInput::placeholder {
    color:  rgba(255, 255, 255, 0.7);
}

.customInput:focus{
    color: #FFF;
    background: rgb(114, 0, 0);
    border-bottom-color: #FF4500;
}

.customInput:disabled {
  background-color: #b3b3b3; /* A light gray shade */
  color: #333; /* A dark gray color to indicate it's disabled but readable */
  cursor: not-allowed; /* Shows the user that the input is disabled */
  opacity: 0.7; /* Slight transparency for a visual effect */
}


.loginButton{
    border: 0;
    background: rgb(24,24,24);
    color: white;
}

.loginButton:hover{
    background: white;
    color: rgb(24, 24, 24);
}

.loginButton:disabled {
  background-color: #b3b3b3; /* Light grey color for disabled state */
  color: #ffffff; /* White or light grey text color */
  cursor: not-allowed; /* Optional: Show a "not-allowed" cursor */
  box-shadow: none; /* Remove shadow for a flat look */
}

/* Container for the phone input component to maintain 100% width */
.phoneInputContainer {
    width: 100%;
    position: relative;
}



/* Styling for the input field to ensure there's space after the country code */
.phoneInputContainer .react-tel-input .form-control {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  background: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 1rem !important;
  outline: none !important;
  box-shadow: none !important;
  transition: all 0.3s ease !important;
}

.react-tel-input .form-control {
  color: white;
  position: relative;
  font-size: 14px;
  margin: 0;
  margin-top: 1rem !important;
  border: none !important;
  padding-left: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  line-height: 35px;
  height: 35px;
  width: 100%; /* Ensure full width */
  outline: none;
}


/* Placeholder styling */
.phoneInputContainer .react-tel-input .form-control {
  width: 100% !important;
  padding: 0.75rem !important;
  background: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 1rem !important;
  outline: none !important;
  box-shadow: none !important;
}

/* Focus styling to match the .customInput focus */
.phoneInputContainer .react-tel-input .form-control:focus {
    color: #FFF !important;
    background: rgb(114, 0, 0) !important; /* Darker background on focus */
    border-bottom: 2px solid #FF4500 !important; /* Add border-bottom to replicate the focus style */
}

/* Country code dropdown button styling */
.phoneInputContainer .flag-dropdown {
    background: rgba(255, 255, 255, 0.1) !important; /* Default background */
    border: none !important;
    border-radius: 5px 0 0 5px !important; /* Rounded corner on the left side to match the input */
    cursor: pointer !important;
    height: 100% !important;
    transition: background 0.3s ease; /* Smooth transition for visual change */
    padding-right: 10px !important; /* Add space between the dropdown and input */
}

/* Hover state for the country code dropdown button */
.phoneInputContainer .flag-dropdown:hover {
    background: #5F0000 !important; /* Change background color on hover */
}

/* Active state for the country code dropdown button (when clicking or holding click) */
.phoneInputContainer .flag-dropdown:active {
    background: #5F0000 !important; /* Change background color when active */
}

/* Customize the dropdown list for selecting country code */
.countryList {
    background: rgb(114, 0, 0) !important;
    color: white !important;
    border-radius: 5px !important;
    max-height: 200px !important; /* Max height for comfortable view */
    overflow-y: auto !important; /* Scroll if there are too many options */
    border: none !important;
}

/* Country list item styling */
.countryList .country {
    padding: 0.5rem !important;
    color: white !important;
}

/* Hover effect for country list items */
.countryList .country:hover {
    background: rgba(255, 255, 255, 0.2) !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: black !important; /* Apply the wheat color */
}

.react-tel-input .country-list .country {
  padding: 0.5rem !important; /* Match padding from your inspect element */
  color: white !important; /* Set text color to white */
}

.progress-container {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: auto;
}

.progress-bar {
  width: 30%;
  height: 5px;
  background: #ccc;
  border-radius: 3px;
}

.progress-bar.active {
  background: #00f; /* Active color */
}

.password-requirements {
  display: block;
  margin-top: 0.5rem; /* Adjust as needed */
  margin-bottom: 0.5rem; /* Prevents elements from touching */
  width: 100%; /* Ensures it fits within its container */
  font-size: 0.8rem; /* Adjust font size as needed */
  overflow-wrap: break-word; /* Allows breaking if necessary */
}

.password-requirements span {
  font-size: 0.85rem; /* Adjust as needed */
  display: inline-block; /* Ensures consistent layout */
}
